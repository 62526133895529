$(document).ready(function () {

  $(document).on("click", ".dropdown-toggle", function () {
    $(".dropdown-menu").removeClass("show");
    $(this).parent().toggleClass("show");
    $(this).parent().siblings().removeClass("show");
    $(this).parent().parent().siblings().children().removeClass("show");
  });
  $(document).on("click", ".dropdown-item", function () {
    const value = $(this).html();
    $(this)
      .parent()
      .parent()
      .siblings(".dropdown-toggle")
      .children("span")
      .html(value);
  });
  $(document).on("click", ".attacking", function () {
    $("#attacking").show();
    $(".no-data, #passing, #defending").hide();
  });
  $(document).on("click", ".passing", function () {
    $("#passing").show();
    $(".no-data, #attacking, #defending").hide();
  });
  $(document).on("click", ".defending", function () {
    $("#defending").show();
    $(".no-data, #attacking, #passing").hide();
  });
  $(document).on("click", ".close-dropdown", function () {
    $(this).parent().parent().parent().removeClass("show");
  });
  $(document).on("click", ".player-dropdown-2 .dropdown-item", function () {
    const value = $(this).html();
    $(this)
      .parent()
      .parent()
      .siblings(".dropdown-toggle")
      .children("span")
      .html(value);
    $(".player-two-season").html(value);
  });
  $(document).on("click", ".player-dropdown-1 .dropdown-item", function () {
    const value = $(this).html();
    $(this)
      .parent()
      .parent()
      .siblings(".dropdown-toggle")
      .children("span")
      .html(value);
    $(".player-one-season").html(value);
  });

  $(document).on("focus", ".search-for-player", function () {
    $(".search-player-list").show();
  });
  $(document).on("click", ".search-player-list p", function () {
    $(".search-player-list").hide();
    $(this).siblings().removeClass("active");
    $(this).addClass("active");
  });
  $(document).on("click", ".add-player", function () {
    $(this).hide();
    $(".reset-btn").show();
    $(".player-search-details, .player-search-form").show();
  });
  $(document).on("click", ".reset-btn", function () {
    $(this).hide();
    $(".add-player").show();
    $(".player-search-details, .player-search-form").hide();
  });
});
